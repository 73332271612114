/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    pre: "pre",
    code: "code",
    h1: "h1",
    a: "a",
    div: "div",
    h2: "h2",
    p: "p",
    hr: "hr",
    ul: "ul",
    li: "li",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "height: 500\n---\n<State initial={undefined}>\n\t{([date, setDate]) => (\n\t\t<Form>\n\t\t\t<DateRangePicker\n\t\t\t\topen\n\t\t\t\tdisableCloseOnClickOutside\n\t\t\t\tvalue={date}\n\t\t\t\tonChange={(v) => setDate(v)}\n\t\t\t/>\n\t\t</Form>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h1, {
    id: "states",
    style: {
      position: "relative"
    }
  }, "States", React.createElement(_components.a, {
    href: "#states",
    "aria-label": "states permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "default",
    style: {
      position: "relative"
    }
  }, "Default", React.createElement(_components.a, {
    href: "#default",
    "aria-label": "default permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "height: 650\n---\n<State initial={{ start: new Date('02/09/21'), end: new Date('02/16/21') }}>\n\t{([date, setDate]) => (\n\t\t<Form>\n\t\t\t<Form.DateRangePicker\n\t\t\t\tlabel='Default Value'\n\t\t\t\tdescription=\"Description goes here...\"\n\t\t\t\tvalue={date}\n\t\t\t\tonChange={(v) => setDate(v)}\n\t\t\t/>\n\t\t\t<Form.DateRangePicker\n\t\t\t\tlabel='Min and Max Date'\n\t\t\t\tminDate={new Date('02/01/21')}\n\t\t\t\tmaxDate={new Date('02/20/21')}\n\t\t\t\tvalue={date}\n\t\t\t\tonChange={(v) => setDate(v)}\n\t\t\t\topen\n\t\t\t\tdisableCloseOnClickOutside\n\t\t\t/>\n\t\t</Form>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h2, {
    id: "placeholder",
    style: {
      position: "relative"
    }
  }, "Placeholder", React.createElement(_components.a, {
    href: "#placeholder",
    "aria-label": "placeholder permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={{}}>\n\t{([date, setDate]) => (\n\t\t<Form>\n\t\t\t<Form.DateRangePicker\n\t\t\t\tlabel=\"With Placeholder\"\n\t\t\t\tvalue={date}\n\t\t\t\tinput={{ placeholder: \"Select the event's date range\" }}\n\t\t\t\tonChange={(v) => setDate(v)}\n\t\t\t/>\n\t\t</Form>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h2, {
    id: "error",
    style: {
      position: "relative"
    }
  }, "Error", React.createElement(_components.a, {
    href: "#error",
    "aria-label": "error permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "For each input, errors for exceeding min or max date is handled internally using a tooltip. For errors that are external, use ", React.createElement(_components.code, null, "Form.DateRangePicker"), "'s ", React.createElement(_components.code, null, "error"), " prop to pass in error and the message."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "height: 1040\n---\n<State initial={{ start: new Date('02/09/21'), end: new Date('02/16/21') }}>\n\t{([date, setDate]) => (\n\t\t<>\n\t\t<Form style={{ height: 540 }}>\n\t\t\t<Form.DateRangePicker\n\t\t\t\tlabel='Exceed Min and Max Date'\n\t\t\t\tminDate={new Date('02/10/21')}\n\t\t\t\tmaxDate={new Date('02/12/21')}\n\t\t\t\tvalue={date}\n\t\t\t\tonChange={(v) => setDate(v)}\n\t\t\t\topen\n\t\t\t\tdisableCloseOnClickOutside\n\t\t\t/>\n\t\t</Form>\n\t\t<Form>\n\t\t\t<Form.DateRangePicker\n\t\t\t\tlabel='Externally Triggered'\n\t\t\t\tvalue={date}\n\t\t\t\tonChange={(v) => setDate(v)}\n\t\t\t\terror\n\t\t\t\topen\n\t\t\t\tdisableCloseOnClickOutside\n\t\t\t/>\n\t\t</Form>\n\t\t</>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h2, {
    id: "required",
    style: {
      position: "relative"
    }
  }, "Required", React.createElement(_components.a, {
    href: "#required",
    "aria-label": "required permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={{ start: new Date('02/09/21'), end: new Date('02/16/21') }}>\n\t{([date, setDate]) => (\n\t\t<Form>\n\t\t\t<Form.DateRangePicker\n\t\t\t\tlabel=\"Date Label\"\n\t\t\t\tvalue={date}\n\t\t\t\tonChange={(v) => setDate(v)}\n\t\t\t\trequired\n\t\t\t/>\n\t\t</Form>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h2, {
    id: "disabled",
    style: {
      position: "relative"
    }
  }, "Disabled", React.createElement(_components.a, {
    href: "#disabled",
    "aria-label": "disabled permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={{ start: new Date('02/09/21'), end: new Date('02/16/21') }}>\n\t{([date, setDate]) => (\n\t\t<Form>\n\t\t\t<Form.DateRangePicker\n\t\t\t\tlabel=\"Date Label\"\n\t\t\t\tonChange={(v) => setDate(v)}\n\t\t\t\tdisabled\n\t\t\t/>\n\t\t\t<Form.DateRangePicker\n\t\t\t\tlabel=\"Date Label\"\n\t\t\t\tvalue={date}\n\t\t\t\tonChange={(v) => setDate(v)}\n\t\t\t\tdisabled\n\t\t\t/>\n\t\t</Form>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h1, {
    id: "options",
    style: {
      position: "relative"
    }
  }, "Options", React.createElement(_components.a, {
    href: "#options",
    "aria-label": "options permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "In the use cases where frequently used ranges are determined, preset options can be used to speed up selection for the users."), "\n", React.createElement(_components.h2, {
    id: "default-1",
    style: {
      position: "relative"
    }
  }, "Default", React.createElement(_components.a, {
    href: "#default-1",
    "aria-label": "default 1 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "height: 500\n---\n<State initial={{}}>\n\t{([date, setDate]) => (\n\t\t<Form>\n\t\t\t<Form.DateRangePicker\n\t\t\t\tlabel='Date Label'\n\t\t\t\tonChange={(v) => setDate(v)}\n\t\t\t\tvalue={date}\n\t\t\t\topen\n\t\t\t\tdisableCloseOnClickOutside\n\t\t\t\tdropdown={{\n\t\t\t\t\toptions: [\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\tvalue: {\n\t\t\t\t\t\t\t\tstart: new Date(new Date().setDate(new Date().getDate() - 7)),\n\t\t\t\t\t\t\t\tend: new Date(),\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\ttext: 'Last 7 Days',\n\t\t\t\t\t\t},\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\tvalue: {\n\t\t\t\t\t\t\t\tstart: new Date(new Date().setDate(new Date().getDate() - 30)),\n\t\t\t\t\t\t\t\tend: new Date(),\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\ttext: 'Last 30 Days',\n\t\t\t\t\t\t},\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\tvalue: {\n\t\t\t\t\t\t\t\tstart: new Date(new Date().setDate(new Date().getDate() - 90)),\n\t\t\t\t\t\t\t\tend: new Date(),\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\ttext: 'Last 90 Days',\n\t\t\t\t\t\t},\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\tvalue: {\n\t\t\t\t\t\t\t\tstart: new Date(new Date().setDate(new Date().getDate() - 365)),\n\t\t\t\t\t\t\t\tend: new Date(),\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\ttext: 'Last 365 Days',\n\t\t\t\t\t\t}\n\t\t\t\t\t]\n\t\t\t\t}}\n\t\t\t/>\n\t\t</Form>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h2, {
    id: "options-that-are-out-of-range",
    style: {
      position: "relative"
    }
  }, "Options that are out of range", React.createElement(_components.a, {
    href: "#options-that-are-out-of-range",
    "aria-label": "options that are out of range permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The options that are out of min max range will be disabled. This example has the same options with min and max date set to only make first option - ", React.createElement(_components.code, null, "Last 7 Days"), " - in range."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "height: 500\n---\n<State initial={{}}>\n\t{([date, setDate]) => (\n\t\t<Form>\n\t\t\t<Form.DateRangePicker\n\t\t\t\tlabel='Date Label'\n\t\t\t\tonChange={(v) => setDate(v)}\n\t\t\t\topen\n\t\t\t\tvalue={date}\n\t\t\t\tfocusTrapOptions={{\n\t\t\t\t\tdisabled: true,\n\t\t\t\t\tautoFocus: false\n\t\t\t\t}}\n\t\t\t\tdisableCloseOnClickOutside\n\t\t\t\tminDate={new Date(new Date().setDate(new Date().getDate() - 7))}\n\t\t\t\tmaxDate={new Date()}\n\t\t\t\tdropdown={{\n\t\t\t\t\toptions: [\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\tvalue: {\n\t\t\t\t\t\t\t\tstart: new Date(new Date().setDate(new Date().getDate() - 7)),\n\t\t\t\t\t\t\t\tend: new Date(),\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\ttext: 'Last 7 Days',\n\t\t\t\t\t\t},\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\tvalue: {\n\t\t\t\t\t\t\t\tstart: new Date(new Date().setDate(new Date().getDate() - 30)),\n\t\t\t\t\t\t\t\tend: new Date(),\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\ttext: 'Last 30 Days',\n\t\t\t\t\t\t},\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\tvalue: {\n\t\t\t\t\t\t\t\tstart: new Date(new Date().setDate(new Date().getDate() - 90)),\n\t\t\t\t\t\t\t\tend: new Date(),\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\ttext: 'Last 90 Days',\n\t\t\t\t\t\t},\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\tvalue: {\n\t\t\t\t\t\t\t\tstart: new Date(new Date().setDate(new Date().getDate() - 365)),\n\t\t\t\t\t\t\t\tend: new Date(),\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\ttext: 'Last 365 Days',\n\t\t\t\t\t\t}\n\t\t\t\t\t]\n\n\t\t\t\t}}\n\t\t\t/>\n\t\t</Form>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h1, {
    id: "label",
    style: {
      position: "relative"
    }
  }, "Label", React.createElement(_components.a, {
    href: "#label",
    "aria-label": "label permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Labels can have a help icon with a ", React.createElement(_components.a, {
    href: "/components/tooltip"
  }, "tooltip"), " to provide additional context to a label."), "\n", React.createElement(_components.h2, {
    id: "help",
    style: {
      position: "relative"
    }
  }, "Help", React.createElement(_components.a, {
    href: "#help",
    "aria-label": "help permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={undefined}>\n\t{([date, setDate]) => (\n\t\t<Form>\n\t\t\t<Form.DateRangePicker\n\t\t\t\tlabel=\"Date Label\"\n\t\t\t\tvalue={date}\n\t\t\t\tlabelProps={{\n\t\t\t\t\thelp: 'This is help text',\n\t\t\t\t\tdirection: 'r',\n\t\t\t\t}}\n\t\t\t\tonChange={(v) => setDate(v)}\n\t\t\t/>\n\t\t</Form>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h2, {
    id: "required-and-optional",
    style: {
      position: "relative"
    }
  }, "Required and Optional", React.createElement(_components.a, {
    href: "#required-and-optional",
    "aria-label": "required and optional permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "A visual indicator can be applied on a Date Picker's label."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<State initial={undefined}>\n\t\t{([date, setDate]) => (\n\t\t\t<Form.DateRangePicker\n\t\t\t\tlabel=\"Date Label\"\n\t\t\t\tvalue={date}\n\t\t\t\tlabelProps={{\n\t\t\t\t\trequired: true,\n\t\t\t\t}}\n\t\t\t\tonChange={(v) => setDate(v)}\n\t\t\t/>\n\t\t)}\n\t</State>\n\t<State initial={undefined}>\n\t\t{([date, setDate]) => (\n\t\t\t<Form.DateRangePicker\n\t\t\t\tlabel=\"Date Label\"\n\t\t\t\tvalue={date}\n\t\t\t\tlabelProps={{\n\t\t\t\t\toptional: true,\n\t\t\t\t}}\n\t\t\t\tonChange={(v) => setDate(v)}\n\t\t\t/>\n\t\t)}\n\t</State>\n</Form>\n")), "\n", React.createElement(_components.h1, {
    id: "multiple-calendar-view",
    style: {
      position: "relative"
    }
  }, "Multiple Calendar View", React.createElement(_components.a, {
    href: "#multiple-calendar-view",
    "aria-label": "multiple calendar view permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "height: 500\n---\n<State initial={undefined}>\n\t{([date, setDate]) => (\n\t\t<Form>\n\t\t\t<DateRangePicker\n\t\t\t\topen\n\t\t\t\tdisableCloseOnClickOutside\n\t\t\t\tvalue={date}\n\t\t\t\tonChange={(v) => setDate(v)}\n\t\t\t\tdropdown={{ views: 2 }}\n\t\t\t/>\n\t\t</Form>\n\t)}\n</State>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "accessibility",
    style: {
      position: "relative"
    }
  }, "Accessibility", React.createElement(_components.a, {
    href: "#accessibility",
    "aria-label": "accessibility permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The user should be able to choose a date by typing into the start and end date input or by selecting a date from the calendar. The user should be able to use either method."), "\n", React.createElement(_components.h2, {
    id: "keyboard-support",
    style: {
      position: "relative"
    }
  }, "Keyboard Support", React.createElement(_components.a, {
    href: "#keyboard-support",
    "aria-label": "keyboard support permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "When focus is on the trigger, press ", React.createElement(_components.code, null, "space"), " to open and ", React.createElement(_components.code, null, "escape"), " to close the calendar."), "\n", React.createElement(_components.li, null, "When picker is open, use ", React.createElement(_components.code, null, "tab"), " / ", React.createElement(_components.code, null, "shift + tab"), " to change focus between inputs, calendar, and options."), "\n", React.createElement(_components.li, null, "When focus is on the calendar:", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "use the arrow keys to traverse through dates"), "\n", React.createElement(_components.li, null, "use ", React.createElement(_components.code, null, "enter"), " to select a date"), "\n", React.createElement(_components.li, null, "use ", React.createElement(_components.code, null, "space"), " to apply, when ", React.createElement(_components.code, null, "Apply"), " button is enabled"), "\n", React.createElement(_components.li, null, "use ", React.createElement(_components.code, null, "page up"), " and ", React.createElement(_components.code, null, "page down"), " to change months"), "\n", React.createElement(_components.li, null, "use ", React.createElement(_components.code, null, "cmd + page up"), " and ", React.createElement(_components.code, null, "cmd + page down"), " to change the view scope between month, year, and decade."), "\n"), "\n"), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "content-guidelines",
    style: {
      position: "relative"
    }
  }, "Content Guidelines", React.createElement(_components.a, {
    href: "#content-guidelines",
    "aria-label": "content guidelines permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Most of the content guidelines from ", React.createElement(_components.a, {
    href: "/patterns/forms/#content-guidelines"
  }, "Forms"), " applies to the Date Picker."), "\n", React.createElement(_components.h3, {
    id: "use-a-label-for-all-input-fields",
    style: {
      position: "relative"
    }
  }, "Use a label for all input fields", React.createElement(_components.a, {
    href: "#use-a-label-for-all-input-fields",
    "aria-label": "use a label for all input fields permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "A label is a short, meaningful description that clearly indicates what the user is expected to enter. Labels should be 1 to 3 words and written in title case. Labels should primarily be nouns. Avoid using labels as CTAs. A label is not inline help and shouldn’t be used to provide instruction."), "\n", React.createElement(_components.h3, {
    id: "follow-capitalization-rules",
    style: {
      position: "relative"
    }
  }, "Follow capitalization rules", React.createElement(_components.a, {
    href: "#follow-capitalization-rules",
    "aria-label": "follow capitalization rules permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Input labels should be written in title case."), "\n", React.createElement(_components.h3, {
    id: "show-hints-formatting-and-requirements",
    style: {
      position: "relative"
    }
  }, "Show hints, formatting, and requirements", React.createElement(_components.a, {
    href: "#show-hints-formatting-and-requirements",
    "aria-label": "show hints formatting and requirements permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Inline help should explain the situation for needing a date range."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "best-practices",
    style: {
      position: "relative"
    }
  }, "Best Practices", React.createElement(_components.a, {
    href: "#best-practices",
    "aria-label": "best practices permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Set minDate and maxDate to reduce user mistakes."), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "related-assets",
    style: {
      position: "relative"
    }
  }, "Related Assets", React.createElement(_components.a, {
    href: "#related-assets",
    "aria-label": "related assets permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "components",
    style: {
      position: "relative"
    }
  }, "Components", React.createElement(_components.a, {
    href: "#components",
    "aria-label": "components permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "For a single date selection, use the ", React.createElement(_components.a, {
    href: "/components/date-picker"
  }, "Date Picker"), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "For user selection of time, use the ", React.createElement(_components.a, {
    href: "/components/time-picker"
  }, "Time Picker"), "."), "\n"), "\n"), "\n", React.createElement(_components.h2, {
    id: "patterns",
    style: {
      position: "relative"
    }
  }, "Patterns", React.createElement(_components.a, {
    href: "#patterns",
    "aria-label": "patterns permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/patterns/forms/"
  }, "Form"), " design pattern for how related controls are ordered."), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "importing",
    style: {
      position: "relative"
    }
  }, "Importing", React.createElement(_components.a, {
    href: "#importing",
    "aria-label": "importing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "code: true\n---\nimport { DateRangePicker } from '@servicetitan/design-system';\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
